export default {
  state: {},
  getters: {},
  actions: {
    getPermissions(content) {
      axios
        .get("/app/permission")
        .then((res) => {
          let permissions = {
            url_info: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            skill: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            level: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            industry: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            user: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            division: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            district: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            upazila: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            thread: {
              access: false,
              manage: false,
            },
            candidate: {
              access: false,
              create: false,
              edit: false,
              delete: false,
              aprroved: false,
            },
            jobs: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            company: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
          };
          res.data.permissions.forEach((permission) => {
            // division Permission
            if (permission.slug == "candidate.access") {
              permissions.candidate.access = true;
            }
            if (permission.slug == "candidate.create") {
              permissions.candidate.create = true;
            }
            if (permission.slug == "candidate.edit") {
              permissions.candidate.edit = true;
            }
            if (permission.slug == "candidate.destroy") {
              permissions.candidate.delete = true;
            }
            if (permission.slug == "candidate.aprroved") {
              permissions.candidate.delete = true;
            }

            if (permission.slug == "division.access") {
              permissions.division.access = true;
            }
            if (permission.slug == "division.create") {
              permissions.division.create = true;
            }
            if (permission.slug == "division.edit") {
              permissions.division.edit = true;
            }
            if (permission.slug == "division.destroy") {
              permissions.division.delete = true;
            }
            // district Permission
            if (permission.slug == "district.access") {
              permissions.district.access = true;
            }
            if (permission.slug == "district.create") {
              permissions.district.create = true;
            }
            if (permission.slug == "district.edit") {
              permissions.district.edit = true;
            }
            if (permission.slug == "district.destroy") {
              permissions.district.delete = true;
            }
            // upazila Permission
            if (permission.slug == "upazila.access") {
              permissions.upazila.access = true;
            }
            if (permission.slug == "upazila.create") {
              permissions.upazila.create = true;
            }
            if (permission.slug == "upazila.edit") {
              permissions.upazila.edit = true;
            }
            if (permission.slug == "upazila.destroy") {
              permissions.upazila.delete = true;
            }

            // User Permission
            if (permission.slug == "level.access") {
              permissions.level.access = true;
            }
            if (permission.slug == "level.create") {
              permissions.level.create = true;
            }
            if (permission.slug == "level.edit") {
              permissions.level.edit = true;
            }
            if (permission.slug == "level.destroy") {
              permissions.level.delete = true;
            }
            if (permission.slug == "skill.access") {
              permissions.skill.access = true;
            }
            if (permission.slug == "skill.create") {
              permissions.skill.create = true;
            }
            if (permission.slug == "skill.edit") {
              permissions.skill.edit = true;
            }
            if (permission.slug == "skill.destroy") {
              permissions.skill.delete = true;
            }
            if (permission.slug == "link.type.access") {
              permissions.url_info.access = true;
            }
            if (permission.slug == "link.type.create") {
              permissions.url_info.create = true;
            }
            if (permission.slug == "link.type.edit") {
              permissions.url_info.edit = true;
            }
            if (permission.slug == "link.type.destroy") {
              permissions.url_info.delete = true;
            }

            // thread access
            if (permission.slug == "thread.access") {
              permissions.thread.access = true;
            }

            if (permission.slug == "thread.manage") {
              permissions.thread.manage = true;
            }

            //industry access
            if (permission.slug == "industry.access") {
              permissions.industry.access = true;
            }

            if (permission.slug == "industry.create") {
              permissions.industry.create = true;
            }

            if (permission.slug == "industry.edit") {
              permissions.industry.edit = true;
            }
            if (permission.slug == "industry.destroy") {
              permissions.industry.delete = true;
            }

            if (permission.slug == "app.user.access") {
              permissions.user.access = true;
            }
            if (permission.slug == "app.user.create") {
              permissions.user.create = true;
            }
            if (permission.slug == "app.user.edit") {
              permissions.user.edit = true;
            }
            if (permission.slug == "app.user.destroy") {
              permissions.user.delete = true;
            }

            //Job access

            if (permission.slug == "company.job.access") {
              permissions.jobs.access = true;
            }
            if (permission.slug == "company.job.create") {
              permissions.jobs.create = true;
            }
            if (permission.slug == "company.job.edit") {
              permissions.jobs.edit = true;
            }
            if (permission.slug == "company.job.destroy") {
              permissions.jobs.delete = true;
            }

            //company access
            if (permission.slug == "company.access") {
              permissions.company.access = true;
            }
            if (permission.slug == "company.create") {
              permissions.company.create = true;
            }
            if (permission.slug == "company.edit") {
              permissions.company.edit = true;
            }
            if (permission.slug == "company.destroy") {
              permissions.company.delete = true;
            }
          });
          content.commit("getPermissions", permissions);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
