import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
import "sweetalert2/dist/sweetalert2.min.css";

import Swal from "sweetalert2";
window.Swal = Swal;

// eslint-disable-next-line no-unused-vars
// import axios from "axios";
window.axios = require("axios");
axios.defaults.baseURL = "https://api.citsmp.com/api";

// Import Vform
import Form from "vform";
window.Form = Form;

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import moment from "moment";
// window.moment = require("moment");

Vue.filter("date_format", function (a) {
  return moment(a).format("Do MMMM YYYY");
});

Vue.filter("date_time_format", function (a) {
  return moment(a).format("Do MMMM YYYY, h:mm A");
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
//Croppie js
Vue.use(VueCroppie);

// Composition API
Vue.use(VueCompositionAPI);
// Pagination Component
Vue.component("pagination", require("laravel-vue-pagination"));

// import core styles
require("@core/scss/core.scss");

import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import locale from "view-design/dist/locale/en-US";
import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

Vue.use(VueQuillEditor /* { default global option } */);
Vue.use(ViewUI, { locale: locale });

// eslint-disable-next-line import/first
import common from "./common";
import axios from "axios";
Vue.mixin(common);

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
