/* eslint-disable arrow-parens */
/* eslint-disable semi */
/* eslint-disable quotes */
import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/link-type",
      name: "link-type",
      component: () => import("@/views/candidate/LinkType.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Link Type",
        breadcrumb: [
          {
            text: "Link Type",
            active: true,
          },
        ],
      },
    },
    {
      path: "/candidates",
      name: "candidates",
      component: () => import("@/views/candidate/CandidateList.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Candidate Lists",
        breadcrumb: [
          {
            text: "Candidate Lists",
            active: true,
          },
        ],
      },
    },
    {
      path: "/profile/:id/:slug/edit",
      name: "profile-edit",
      component: () => import("@/views/candidate/partials/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/skills",
      name: "skills",
      component: () => import("@/views/candidate/Skill.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Skills Manage",
        breadcrumb: [
          {
            text: "Skills Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/education-level",
      name: "education-level",
      component: () => import("@/views/candidate/Level.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Education Level",
        breadcrumb: [
          {
            text: "Education Level",
            active: true,
          },
        ],
      },
    },
    {
      path: "/division",
      name: "division",
      component: () => import("@/views/settings/DivisionManage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Division",
        breadcrumb: [
          {
            text: "Division",
            active: true,
          },
        ],
      },
    },
    {
      path: "/district",
      name: "district",
      component: () => import("@/views/settings/DistrictManage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "District",
        breadcrumb: [
          {
            text: "District",
            active: true,
          },
        ],
      },
    },
    {
      path: "/upazila",
      name: "upazila",
      component: () => import("@/views/settings/UpazilaManage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Upazila",
        breadcrumb: [
          {
            text: "Upazila",
            active: true,
          },
        ],
      },
    },

    {
      path: "/industry",
      name: "industry",
      component: () => import("@/views/candidate/Industry.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Industry Manage",
        breadcrumb: [
          {
            text: "Industry",
            active: true,
          },
        ],
      },
    },
    {
      path: "/profile/:id",
      name: "profile",
      component: () => import("@/views/candidate/partials/Profile.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },
    {
      path: "/change-password",
      name: "change-password",
      component: () => import("@/views/PasswordChange.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Password",
        breadcrumb: [
          {
            text: "Change Password",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },

    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/job/create",
      name: "create-job",
      component: () => import("@/views/company/CreateJob.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Create Job",
        breadcrumb: [
          {
            text: "Create Job",
            active: true,
          },
        ],
      },
    },
    {
      path: "/jobs",
      name: "job-list",
      component: () => import("@/views/company/ViewJobs.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "View Jobs",
        breadcrumb: [
          {
            text: "Job Lists",
            to: "/jobs",
          },
          {
            text: "View Jobs",
            active: true,
          },
        ],
      },
    },
    {
      path: "/job/edit/:id",
      name: "edit-job",
      component: () => import("@/views/company/EditJob.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Edit",
        breadcrumb: [
          {
            text: "Job Lists",
            to: "/jobs",
          },
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/job/single/show/:id",
      name: "single-job",
      component: () => import("@/views/company/SingleJob.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Single job",
        breadcrumb: [
          {
            text: "Single Job",
            active: true,
          },
        ],
      },
    },
    {
      path: "/company",
      name: "company",
      component: () => import("@/views/company/Company.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Company",
        breadcrumb: [
          {
            text: "Company List",
            active: true,
          },
        ],
      },
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  if (localStorage.getItem("token")) {
    store.dispatch("CheckAuth");
  }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.visitor)) {
    if (store.getters.loggedIn) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
